'use strict';

angular.module('enervexSalesappApp').factory('FancalcJob', function ($resource) {
	return $resource('/api/accounts/:accountId/legacy-proxy/fancalc-jobs/:id/:sync', {
		id: '@id',
		sync: '@sync'
	},
	{
		update: {
			method: 'PUT'
		},
		syncAll: {
			method: 'POST',
			params: {
				sync: 'sync-all',
				accountId:'@accountId'
			}
		},
	});
});
